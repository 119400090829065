import React from "react";
import "../styles/header.css";

const Header = () => {
  return (
    <header className="header">
      <div className="header-container">
        <div className="logo"></div>
        {/* <nav className="nav">
          <a href="#" className="nav-item">Guides</a>
          <a href="#" className="nav-item">API Reference</a>
          <a href="#" className="nav-item">Community</a>
          <a href="#" className="nav-item">Discord</a>
          <a href="#" className="nav-item">Blog</a>
          <a href="#" className="nav-item">FAQ</a>
          <a href="#" className="nav-item">Bug Bounty</a>
          <a href="#" className="nav-item">Announcements</a>
        </nav> */}
        <div className="search-container">
          <input type="text" className="search-input" placeholder="Search" />
          <button className="search-button">CTRL + K</button>
        </div>
      </div>
    </header>
  );
};

export default Header;
