import logo from './logo.svg';


import './App.css';



import { Routesrs } from './route';

function App() {
  return (
    <Routesrs />      
  );
}

export default App;
